.star {
    animation: rotate 2s cubic-bezier(0.175, 0.885, 0.32, 1.275) infinite;
}

@keyframes rotate {
    0%,
    100% {
        transform: rotate(0deg);
    }
    33% {
        transform: rotate(66deg);
    }
    66% {
        transform: rotate(-66deg);
    }
}

.live-on {
    animation: rotate3d 3s linear infinite;
}

.rotate-stars {
    animation: rotate 90s linear infinite;
}
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.rotate3d {
    animation: rotate3d 2.5s linear infinite;
}

@keyframes rotate3d {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}

.shine {
    position: relative;
    ::after {
        position: absolute;
        top: 0;
        left: -100%;
        z-index: 3;
        display: block;
        content: "";
        width: 50%;
        height: 100%;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
        transform: skewX(-25deg);
    }
}
.shine:hover {
    ::after {
        animation: shine 1.1s;
        left: 125%;
    }
}

@keyframes shine {
    0% {
        left: -100%;
    }
    100% {
        left: 125%;
    }
}

// planet
$basic-time: 20s;
$additional-time: 5s;

.orbit-round {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    // border: 1px solid var(--color-primary);
    border: 1px solid rgba(39, 39, 49, 0.5);
    top: 0;
    left: 0;
}

.orbit-1 {
    position: "inherit";
    animation: orbit $basic-time + $additional-time * 0 linear infinite;
    &::before {
        position: absolute;
        content: url("../../public/images/planets/planet-1-1.png");
        top: -13px;
        left: 50%;
        z-index: 1;
    }
}
.orbit-2 {
    position: "inherit";
    animation: orbit $basic-time + $additional-time * 1 linear infinite;
    &::before {
        position: absolute;
        content: url("../../public/images/planets/planet-2-1.png");
        top: 50%;
        left: -32px;
        z-index: 1;
    }
    &::after {
        position: absolute;
        content: url("../../public/images/planets/planet-2-2.png");
        top: 50%;
        right: -36px;
        z-index: 1;
    }
}
.orbit-3 {
    position: "inherit";
    animation: orbit $basic-time + $additional-time * 2 linear infinite;
    &::before {
        position: absolute;
        content: url("../../public/images/planets/planet-3-1.png");

        top: calc(10% - 4px);
        left: 18%;
        z-index: 1;
    }
    &::after {
        position: absolute;
        content: url("../../public/images/planets/planet-3-2.png");
        top: 50%;
        right: -9px;
        z-index: 1;
    }
}
.orbit-4 {
    position: "inherit";
    animation: orbit $basic-time + $additional-time * 3 linear infinite;
    &::before {
        position: absolute;
        content: url("../../public/images/planets/planet-4-1.png");
        top: -16px;
        left: 50%;
        z-index: 1;
    }
}
.orbit-5 {
    position: "inherit";
    animation: orbit $basic-time + $additional-time * 4 linear infinite;
    &::before {
        position: absolute;
        content: url("../../public/images/planets/planet-5-1.png");
        top: calc(10% - 37px);
        left: 15%;
        z-index: 1;
    }
    &::after {
        position: absolute;
        content: url("../../public/images/planets/planet-5-2.png");
        top: 50%;
        right: -37px;
        z-index: 1;
    }
}
.orbit-6 {
    position: "inherit";
    animation: orbit $basic-time + $additional-time * 5 linear infinite;
    &::before {
        position: absolute;
        content: url("../../public/images/planets/planet-6-1.png");
        top: calc(10% - 5px);
        left: 18%;
        z-index: 1;
    }
    &::after {
        position: absolute;
        content: url("../../public/images/planets/planet-6-2.png");
        top: 50%;
        right: -20px;
        z-index: 1;
    }
}

@keyframes orbit {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

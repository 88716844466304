:root {
  --color-primary: #7f61ef;
  --color-secondary: #2a2b3a;
  --color-third: rgb(1, 3, 20);
  --color-accent: #55eaf7;
  --color-title: #fff;
  --color-text: rgba(255, 255, 255, 0.6);
  --color-text-2: #7c7b97;

  --color-bg: #050505;
  --color-link: #55eaf7;

  --color-white: #fff;
  --color-black: #000;
  --color-blue: #00f4f4;
  --color-gray: #626d7a;

  --color-error: rgb(255, 88, 88);
  --color-error-25: rgba(255, 88, 88, 0.2);
  --color-warning: rgb(225, 159, 45);
  --color-warning-25: rgba(225, 159, 45, 0.2);
  --color-success: rgb(105, 255, 138);
  --color-success-25: rgba(105, 255, 138, 0.2);

  --linear-primary: linear-gradient(90deg, #2745ea, #cf7bf4);
  --linear-secondary: linear-gradient(180deg, #1e1c45 0%, rgba(30, 28, 69, 0) 171.01%);
  --linear-border: linear-gradient(45deg, #fff 0%, rgba(113, 113, 113, 0) 60%, #fff 100%);

  --radial-primary: radial-gradient(217.23% 100.08% at 50% 18.33%, rgba(0, 0, 0, 0) 21.67%, #63e 69.34%, #fff 100%);
  --radial-secondary: radial-gradient(70.71% 70.71% at 50% 50%, #fff 30%, rgba(255, 255, 255, 0.5) 84.77%);

  --shadow-primary: 0px 4px 100px 0px rgba(102, 77, 255, 0.2);
}

#root {
  min-height: 100vh;
}

body {
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif !important;
  overflow-x: clip;
  background-color: var(--color-bg) !important;
}

::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: var(--color-third);
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(119, 121, 143, 0.5);
}

.mini-scroll::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: transparent;
}

.mini-scroll::-webkit-scrollbar {
  width: 4px;
  background-color: rgba(158, 255, 255, 0.1);
}

.mini-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(158, 255, 255, 0.75);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: transparent 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

img {
  max-width: 100%;
}
a,
.MuiLink-root {
  text-decoration: none;
  color: var(--color-link);
}
.Toastify__toast {
  position: relative;
  border-radius: 16px;
  &::before {
    content: "";
    position: absolute;
    inset: 0px;
    border-radius: 16px;
    padding: 1px;
    background: var(--linear-primary);
    -webkit-mask:
      linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px) content-box content-box,
      linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px);
    -webkit-mask-composite: xor;
    z-index: -1;
  }
}

// .MuiDrawer-modal .MuiBackdrop-root,
// .MuiModal-root .MuiBackdrop-root {
//   backdrop-filter: blur(2px);
// }

.MuiPopover-root .MuiPaper-root {
  background: var(--color-third);
  color: var(--color-primary);
  box-shadow: none;
}

.MuiTypography-root:not(.special-font) {
  font-family: "Poppins", sans-serif !important;
}
@media (max-width: 576px) {
  .MuiTypography-body1 {
    font-size: 14px !important;
  }
}
